import ConditionsMixin from './conditions_mixin'
export default {
  mixins: [ConditionsMixin],
  props: {
    disableCondition: {
      type: Object,
      editor: 'Conditions',
      options: { label: 'Блокировать' },
      default: () => {
        return {
          type: 'never',
          allowOldFormat: false,
          isComplex: true,
          condition_type: 'and',
          conditions: [],
          expressions: {
            logicalOperator: 'and',
            children: []
          }
        }
      }
    }
  },
  computed: {
    isDisabled () {
      return this.checkConditions(this.disableCondition)
    }
  },
  mounted () {
    if (this.disabled) {
      this.$set(this.hiddenCondition, 'type', 'always')
      this.disabled = false
    }
  }
}
